<template>
  <div>
    <div class="visits__months">
      <div class="visits__months__stat">
        <slot name="stat"></slot>
      </div>
      <div class="prev">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_left"
          @click="downMonth"
        ></vs-button>
      </div>
      <div class="month-name">{{ activePage.name }}</div>
      <div class="next">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_right"
          @click="upMonth"
        ></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import getSchoolYearMonths from "@/plugins/schoolYears";

export default {
  props: ["startMonth", "schoolyear"],
  data() {
    return {
      pages: [],
      activePage: {},
      today: new Date(),
    };
  },
  methods: {
    getPages: function () {
      if (!this.schoolyear) return;
      let months = getSchoolYearMonths(this.schoolyear);
      this.pages = months.map((m, index) => {
        const page = {
          name: m.name,
          index,
          current: m.current == this.today.getMonth() + 1,
          month: m.current,
          year: m.year,
        };

        return page;
      });
    },
    setCurrentPage: function () {
      if (this.startMonth) {
        this.activePage = this.pages.find((p) => p.month == this.startMonth);
      }

      if (!this.startMonth || !this.activePage) {
        this.activePage = this.pages.find((p) => p.current);
      }
    },
    downMonth: function () {
      const index = this.activePage.index;
      if (index > 0) {
        this.activePage = this.pages[index - 1];
      }
    },
    upMonth: function () {
      const index = this.activePage.index;
      if (index + 1 < this.pages.length) {
        this.activePage = this.pages[index + 1];
      }
    },
  },
  watch: {
    startMonth: function () {
      this.getPages();
    },
    activePage: function (newV) {
      this.$emit("changedMonth", newV);
    },
  },
  created: function () {
    this.getPages();
    this.setCurrentPage();
  },
};
</script>

<style>
</style>