var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lesson-other-teacher-form"},[_c('div',{staticClass:"field other-teachers"},[_c('vs-checkbox',{model:{value:(_vm.isSetSubTeachers),callback:function ($$v) {_vm.isSetSubTeachers=$$v},expression:"isSetSubTeachers"}},[_vm._v("Set Sub Teachers")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","disabled":!_vm.isSetSubTeachers,"multiple":"","clearable":"","placeholder":"Select teacher","selectable":function (option) { return _vm.isSetSubTeachers &&
          option.id != _vm.mainTeacher &&
          !_vm.otherTeachers.includes(option.id) &&
          !_vm.assistants.includes(option.id); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var nickname = ref.nickname;
          var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.otherTeachers),callback:function ($$v) {_vm.otherTeachers=$$v},expression:"otherTeachers"}})],1),_c('div',{staticClass:"field assistants"},[_c('vs-checkbox',{model:{value:(_vm.isSetAssistants),callback:function ($$v) {_vm.isSetAssistants=$$v},expression:"isSetAssistants"}},[_vm._v("Set Assistants")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","multiple":"","clearable":"","disabled":!_vm.isSetAssistants,"placeholder":"Select teacher","selectable":function (option) { return option.id != _vm.mainTeacher &&
          !_vm.otherTeachers.includes(option.id) &&
          !_vm.assistants.includes(option.id); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var nickname = ref.nickname;
          var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.assistants),callback:function ($$v) {_vm.assistants=$$v},expression:"assistants"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }