<template>
  <div class="lesson-other-teacher-form">
    <div class="field other-teachers">
      <vs-checkbox v-model="isSetSubTeachers">Set Sub Teachers</vs-checkbox>
      <v-select
        label="nickname"
        :options="teachers"
        v-model="otherTeachers"
        :reduce="(item) => item.id"
        autocomplete="on"
        :disabled="!isSetSubTeachers"
        multiple
        clearable
        placeholder="Select teacher"
        :selectable="
          (option) =>
            isSetSubTeachers &&
            option.id != mainTeacher &&
            !otherTeachers.includes(option.id) &&
            !assistants.includes(option.id)
        "
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ nickname }}
        </template>
      </v-select>
    </div>
    <div class="field assistants">
      <vs-checkbox v-model="isSetAssistants">Set Assistants</vs-checkbox>
      <v-select
        label="nickname"
        :options="teachers"
        v-model="assistants"
        :reduce="(item) => item.id"
        autocomplete="on"
        multiple
        clearable
        :disabled="!isSetAssistants"
        placeholder="Select teacher"
        :selectable="
          (option) =>
            option.id != mainTeacher &&
            !otherTeachers.includes(option.id) &&
            !assistants.includes(option.id)
        "
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ nickname }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapState } from "vuex";
export default {
  props: ["lesson"],
  data() {
    let otherTeachers = [],
      assistants = [];

    return {
      otherTeachers,
      assistants,
      proxy: process.env.VUE_APP_PROXY,
      isSetSubTeachers: false,
      isSetAssistants: false,
    };
  },
  components: {
    vSelect,
  },
  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
    }),
    mainTeacher: function () {
      if (this.lesson) return this.lesson.main_teacher.id;
      return undefined;
    },
  },
  methods: {
    changeTeachersHandler: function () {
      let teachers = {};
      const { otherTeachers, assistants } = this;
      if (this.isSetSubTeachers) {
        teachers["otherTeachers"] = otherTeachers;
      }
      if (this.isSetAssistants) {
        teachers["assistants"] = assistants;
      }
      this.$emit("changeTeachers", teachers);
    },
  },
  watch: {
    otherTeachers: function () {
      this.changeTeachersHandler();
    },
    assistants: function () {
      this.changeTeachersHandler();
    },
    isSetSubTeachers: function (newV) {
      if (!newV) {
        this.otherTeachers = [];
      }
    },
    isSetAssistants: function (newV) {
      if (!newV) {
        this.assistants = [];
      }
    },
  },
  mounted: async function () {
    if (!this.teachers.length) {
      // console.log("get tacher");
      await this.$store.dispatch("teachers/getTeachersAction");
    }
    setTimeout(() => {
      if (this.lesson.id) {
        if (this.lesson.other_teachers)
          this.otherTeachers = Object.keys(this.lesson.other_teachers).map(
            (i) => parseInt(i, 10),
          );
        if (this.lesson.assistants)
          this.assistants = Object.keys(this.lesson.assistants).map((i) =>
            parseInt(i, 10),
          );
      }
    }, 200);
  },
};
</script>

<style>
</style>