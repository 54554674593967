<template>
  <div class="group-view">
    <div class="status-bar">
      <div class="status-bar__back-button">
        <vs-button
          icon="arrow_back"
          class
          type="flat"
          color="dark"
          to="/groups"
          title="back to group list"
        ></vs-button>
      </div>
      <div class="status-bar__back-button">
        <vs-button
          icon="navigate_before"
          class
          type="flat"
          color="dark"
          :title="`Go to previous group ${prevGroup.groupName}`"
          v-if="prevGroup.name"
          @click="goToGroup(prevGroup)"
        ></vs-button>
      </div>
      <h2 class="status-bar__title">
        <vs-avatar
          :color="group.color"
          icon="portrait"
          v-if="group.is_tutor"
        ></vs-avatar>
        <vs-avatar :color="group.color" icon="people" v-else></vs-avatar>
        {{ group.name }}
      </h2>
      <div class="status-bar__back-button">
        <vs-button
          icon="navigate_next"
          class
          type="flat"
          color="dark"
          :title="`Go to next group ${nextGroup.groupName}`"
          v-if="nextGroup.name"
          @click="goToGroup(nextGroup)"
        ></vs-button>
      </div>
      <div class="status-bar__timetable">
        <span v-for="(d, i) in days" :key="i">
          <vs-icon :icon="d.room.icon" v-if="d.room"></vs-icon>
          {{ d.name }} - {{ d.time }}
        </span>
      </div>
      <div v-if="group.in_holidays">
        <vs-chip
          ><vs-avatar icon="celebration"></vs-avatar>
          Группа работает в праздники
        </vs-chip>
      </div>
      <div class="status-bar__manage-buttons" v-if="$can('manage', 'group')">
        <vs-button
          icon="edit"
          class="status-bar__edit-button"
          type="flat"
          color="dark"
          @click="popupActive = true"
          >Edit</vs-button
        >
      </div>
      <div class="status-bar__manage-delimiter"></div>
      <div class="status-bar__manage-buttons current_books">
        <group-current-books-view :current_books="group.current_books" />
        <group-current-books-edit
          :current_books="group.current_books"
          v-if="$can('manage', 'current_books')"
        />
      </div>
    </div>
    <div class="view-content">
      <Split>
        <SplitArea :size="65">
          <div class="panel-view">
            <GroupTabs>
              <template v-slot:attendance>
                <div class="view-manage">
                  <div class="view-manage__button">
                    <vs-button
                      icon="view_week"
                      :type="!lessonsTableView ? 'border' : 'flat'"
                      title="Attendance view"
                      size="small"
                      @click="lessonsTableView = false"
                      color="dark"
                    ></vs-button>
                  </div>
                  <div class="view-manage__button">
                    <vs-button
                      icon="menu"
                      :type="lessonsTableView ? 'border' : 'flat'"
                      title="Table view"
                      size="small"
                      @click="lessonsTableView = true"
                      color="dark"
                    ></vs-button>
                  </div>
                </div>
                <div class="group-tasks">
                  <tasks-wrapper
                    place_type="group"
                    :place_id="parseInt(id, 10)"
                    :group="group"
                  />
                </div>
                <div v-if="!lessonsTableView">
                  <GroupAttendance :group="group" v-if="group.id" />
                </div>
                <div v-else>
                  <group-lessons-list @updateLessons="updateLessonsHandler" />
                </div>

                <group-tests
                  :group="group"
                  v-if="group.id && groupResults.length"
                />
              </template>
              <template v-slot:payments>
                <GroupPaymentsWrapper :group="group" v-if="group.id" />
              </template>
              <template v-slot:book-outs>
                <book-outs-wrapper :group="group" />
              </template>
            </GroupTabs>
          </div>
        </SplitArea>
        <SplitArea :size="35">
          <vs-tabs>
            <vs-tab label="Comments" icon="comment">
              <div class="con-tab-ejemplo">
                <Comments :itemId="$route.params.group_id" itemType="Groups" />
              </div>
            </vs-tab>
            <vs-tab label="Books" icon="import_contacts">
              <div class="con-tab-ejemplo">
                <group-books />
              </div>
            </vs-tab>
          </vs-tabs>
        </SplitArea>
      </Split>
    </div>
    <div v-if="popupActive">
      <vs-popup
        class="form-popup"
        title="Group"
        fullscreen
        :active.sync="popupActive"
      >
        <GroupsForm :group="group" @closePopup="closePopup" />
      </vs-popup>
    </div>
    <div
      class="result-tooltip"
      v-if="popupTestResultActive"
      :style="{
        left: popupTestResultData.left + 'px',
        top: popupTestResultData.top + 'px',
      }"
    >
      <result-info :data="popupTestResultData.popup" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GroupTabs from '@/components/groups/groupTabs.vue'
import GroupsForm from '@/components/groups/groupsForm.vue'
import GroupAttendance from '@/components/groups/groupAttendance.vue'
import GroupPaymentsWrapper from '@/components/groups/orders/wrapper.vue'
import Comments from '@/components/comments/comments.vue'
import GroupBooks from '../components/books/groups/groupBooks.vue'
import BookOutsWrapper from '../components/books/groups/bookOutsWrapper.vue'
import GroupTests from '../components/groups/groupTests.vue'
import GroupLessonsList from '../components/groups/lessons/groupLessonsList.vue'
import TasksWrapper from '../components/tasks/tasksWrapper.vue'
import eventBus from '@/eventBus.js'
import resultInfo from '@/components/results/resultInfo.vue'
import GroupCurrentBooksView from '@/components/books/groups/currentBooks/groupCurrentBooksView.vue'
import GroupCurrentBooksEdit from '@/components/books/groups/currentBooks/groupCurrentBooksEdit.vue'

export default {
  name: 'GroupView',
  title: 'SE-Group',
  data() {
    return {
      popupActive: false,
      id: this.$route.params.group_id,
      today: new Date(),
      lessonsTableView: false,
      popupTestResultActive: false,
      popupTestResultData: undefined,
    }
  },
  components: {
    GroupsForm,
    GroupAttendance,
    Comments,
    GroupTabs,
    GroupPaymentsWrapper,
    GroupBooks,
    GroupTests,
    BookOutsWrapper,
    GroupLessonsList,
    TasksWrapper,
    resultInfo,
    GroupCurrentBooksView,
    GroupCurrentBooksEdit,
  },
  computed: {
    ...mapState({
      group: state => state.groups.singleGroup,
      groups: state => state.groups.groups,
      lessons: state => state.lessons.lessons,
      currentSchoolYear: state => state.years.currentSchoolYear,
      rooms: state => state.rooms.rooms,
      groupResults: state => state.results.results,
    }),
    nextGroup: function() {
      const selfIndex = this.groups.findIndex(g => g.id == this.id)
      if (this.groups.length > selfIndex + 1) {
        const nextGroup = this.groups[selfIndex + 1]
        return {
          name: 'Group',
          params: { group_id: nextGroup.id },
          groupName: nextGroup.name,
        }
      }
      return {}
    },
    prevGroup: function() {
      const selfIndex = this.groups.findIndex(g => g.id == this.id)
      if (selfIndex - 1 >= 0) {
        const prevGroup = this.groups[selfIndex - 1]
        return {
          name: 'Group',
          params: { group_id: prevGroup.id },
          groupName: prevGroup.name,
        }
      }
      return {}
    },
    days: function() {
      if (!this.group.lessons_time) return []
      return this.group.lessons_time
        .map(l => {
          const { name, time, room } = l

          return {
            name,
            time,
            room: this.rooms.find(r => r.id == room),
          }
        })
        .sort((a, b) => {
          return (
            this.$moment(a.name, 'ddd').isoWeekday() -
            this.$moment(b.name, 'ddd').isoWeekday()
          )
        })
    },
  },
  created: async function() {
    const { group_id } = this.$route.params
    if (this.rooms.length == 0) {
      await this.$store.dispatch('rooms/getRoomsAction')
    }
    await this.$store.dispatch('groups/getSingleGroupAction', {
      id: group_id,
    })
    await this.$store.dispatch('books/getGroupBooksAction', { group_id })
    await this.$store.dispatch('results/getResultsByGroupAction', {
      group_id,
    })
    if (this.groups.length == 0) {
      if (!this.currentSchoolYear.id) {
        await this.$store.dispatch('years/getYearsAction')
      }
      const data = {
        schoolyear_id: this.currentSchoolYear.id,
      }
      this.$store.dispatch('groups/getGroupsAction', { data })
    }
    this.$store.dispatch('lessons/getLessonsByGroupsAction', {
      group_id,
    })
    eventBus.$on('showTestResult', data => {
      this.popupTestResultData = data
      this.popupTestResultActive = true
      data.left = data.left + 10
      data.top = data.top + 10

      setTimeout(() => {
        let popupHeight = document.querySelector('.result-tooltip')
          ?.offsetHeight
        let wintHeight = window.innerHeight
        if (data.top + popupHeight > wintHeight) {
          data.top = wintHeight - popupHeight
        }
      }, 50)
    })
    eventBus.$on('hideTestResult', () => {
      this.popupTestResultData = undefined
      this.popupTestResultActive = false
    })
  },
  methods: {
    editGroup: function() {
      this.popupActive = true
    },
    closePopup: function() {
      this.popupActive = false
    },
    goToGroup: function(targetGroup) {
      this.$router.push(targetGroup)
    },
    updateLessonsHandler: function() {
      const group_id = this.group.id
      this.$store.dispatch('lessons/getLessonsByGroupsAction', {
        group_id,
      })
    },
  },

  beforeDestroy() {
    this.$store.dispatch('groups/clearAction')
    this.$store.dispatch('books/clearGroupBooksAction')
    this.$store.dispatch('lessons/clearGroupLessonsAction')
    eventBus.$off('showTestResult')
    eventBus.$off('hideTestResult')
  },
}
</script>

<style lang="scss">
.view-manage {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 5px;
  &__button {
    .vs-button {
      float: none;
    }
  }
}
.status-bar__timetable {
  // &::before {
  //   content: "";
  //   display: inline-block;
  //   border: 1px solid #ccc;
  //   height: 1rem;
  //   vertical-align: middle;
  // }
  & > span {
    display: inline-block;
    font-size: 0.8rem;
    margin: 0 0.5rem;
    & > .vs-icon {
      font-size: 1.3rem;
      margin-right: 3px;
    }
  }
}
</style>
