<template>
  <div class="table order__table-wrapper">
    <table v-if="Object.keys(orders).length">
      <thead>
        <tr>
          <th></th>
          <!-- <th>Lessons count</th> -->
          <th>To pay in {{ currentMonth.name }}</th>
          <th>Last Payment</th>
          <th>Payed</th>
          <th>Credit</th>
          <th>Sended</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="s in existStudents"
          :key="s.id"
          @click="expandPayments($event, orders[s.id])"
        >
          <td>
            <router-link
              :to="{ name: 'Student', params: { student_id: s.id } }"
              >{{ s.name }}</router-link
            >
          </td>
          <!-- <td></td> -->
          <td
            class="highlight discount"
            :class="{
              d15: orders[s.id].discount >= 15,
              d25: orders[s.id].discount >= 25,
              d50: orders[s.id].discount >= 50,
            }"
            :title="
              `${orders[s.id].discount || 0} % - ${orders[s.id]
                .discount_value || 0}р.`
            "
          >
            {{ orders[s.id].value }} р.
          </td>

          <td v-if="orders[s.id].last_payment_date">
            {{
              $moment(orders[s.id].last_payment_date * 1000).format(
                'DD.MM.YYYY HH:mm'
              )
            }}
          </td>
          <td v-else>-</td>
          <td class="highlight">{{ orders[s.id].payments }} р.</td>
          <td
            class="credit"
            :class="{ negative: orders[s.id].balance_start < 0 }"
          >
            {{ orders[s.id].balance_start }} р.
          </td>
          <td>
            <vs-button
              v-if="!orders[s.id].sended"
              type="flat"
              @click="sendHandle($event, orders[s.id].id)"
              icon="mail"
              size="small"
              >Send</vs-button
            >
            <span v-else>
              <vs-icon
                icon="done"
                color="success"
                :title="
                  `Message sended at: ${$moment(
                    orders[s.id].sended * 1000
                  ).format('DD.MM.YY HH:mm')}`
                "
                @click="sendHandle($event, orders[s.id].id)"
              ></vs-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <vs-popup
      class="form-popup payment__popup"
      classContent="payment__popup-content"
      :title="popuptTitle"
      :active.sync="popupActive"
      @keydown.esc="popupActive = !popupActive"
    >
      <PaymentWrapper
        v-if="popupActive"
        :student="selectedOrder.student"
        :group="group"
        :order="selectedOrder"
        @changedPayments="changedPayments"
      >
        <template v-slot:order>
          <div class="order-info">
            <span>Order #{{ selectedOrder.id }}</span>
            <span>
              <vs-icon icon="query_builder" />
            </span>
            <span class="highlight">{{
              $moment()
                .year(selectedOrder.year)
                .month(selectedOrder.month)
                .format('MMMM YYYY')
            }}</span>
            <span
              ><vs-chip>
                <vs-avatar icon="people" :color="selectedOrder.group.color" />
                Group {{ selectedOrder.group.name }}
              </vs-chip></span
            >
            <span
              ><vs-chip>
                <vs-avatar icon="person" />
                {{ selectedOrder.student.name }}
              </vs-chip></span
            >
            <span class="highlight">{{ selectedOrder.value }}р.</span>
            <span
              class="credit highlight"
              :class="{ negative: selectedOrder.current_credit < 0 }"
              >{{ selectedOrder.current_credit }}р.</span
            >
          </div>
        </template>
      </PaymentWrapper>
    </vs-popup>
  </div>
</template>

<script>
import PaymentWrapper from '@/components/payments/singleForm/wrapper'
export default {
  props: ['group', 'students', 'orders', 'currentMonth'],
  data() {
    return {
      popupActive: false,
      popuptTitle: '',
      selectedOrder: {},
    }
  },
  components: { PaymentWrapper },
  computed: {
    ordersStat: function() {
      const studentIds = this.students.map(s => s.id)
      const orders = Object.values(this.orders).filter(o =>
        studentIds.includes(o.student.id)
      )
      const orders_groups_sum = orders.reduce((sum, o) => sum + o.value, 0)
      const payments_sum = orders.reduce((sum, o) => sum + o.payments, 0)
      return {
        orders_groups_sum,
        payments_sum,
      }
    },
    existStudents: function() {
      const students = this.students.filter(s =>
        Object.keys(this.orders).includes(s.id.toString())
      )
      return students
    },
  },
  methods: {
    expandPayments: function(e, tr) {
      e.preventDefault()
      this.popupActive = true
      this.popuptTitle = `Payments to order #${tr.id}`
      this.selectedOrder = tr
    },
    changedPayments: function() {
      this.$emit('changedPayments')
    },
    sendHandle: function(e, orderId) {
      e.preventDefault()
      e.stopPropagation()

      this.$emit('sendOrder', orderId)
    },
  },
}
</script>

<style></style>
