<template>
  <div class="visits__months__stat">
    <div v-if="statistic.monthName">
      {{statistic.monthName}} all: {{statistic.allCount}} done:{{statistic.doneCount}}
      <span
        style="color:red"
        v-if="statistic.cancelledCount"
      >cancelled: {{statistic.cancelledCount}}</span>
    </div>
    <div v-else>No previous month</div>
  </div>
</template>

<script>
export default {
  props: ["statistic"]
};
</script>

<style>
.visits__months__stat {
  width: 245px;
  font-size: 0.8em;
}
</style>