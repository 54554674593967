<template>
  <div class="book-out-form">
    <div class="field">
      <label for="">Teacher</label>
      <v-select
        label="nickname"
        :options="teachers"
        v-model="teacher_id"
        :reduce="(item) => item.id"
        autocomplete="on"
        clearable
        placeholder="Select book"
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ nickname }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <label for="">Give out from</label>
      <v-date-picker v-model="deliver_from">
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <vs-input
              class="calendar-input"
              :value="`${inputValue}`"
              v-on="inputEvents"
              size="large"
            />
          </div>
        </template>
      </v-date-picker>
    </div>
    <div class="field" v-if="delivered_date">
      <label for="">Was given</label>
      <v-date-picker v-model="delivered_date">
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <vs-input
              class="calendar-input"
              :value="`${inputValue}`"
              v-on="inputEvents"
              size="large"
            />
            <div v-if="lesson">({{ lesson.name }})</div>
          </div>
        </template>
      </v-date-picker>
    </div>
    <div class="field">
      <vs-textarea label="Comment" v-model="comment" />
    </div>
    <vs-button icon="save" @click="save">Save</vs-button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
export default {
  props: ["out"],
  data() {
    const { id, teacher, delivered_date, deliver_from, comment, lesson } =
      this.out;
    return {
      proxy: process.env.VUE_APP_PROXY,
      id,
      delivered_date: delivered_date ? new Date(delivered_date * 1000) : null,
      deliver_from: new Date(deliver_from * 1000),
      comment,
      lesson,
      teacher_id: teacher.id,
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
    }),
  },
  components: { vSelect },
  methods: {
    save: function () {
      const { teacher_id, deliver_from, delivered_date, comment, lesson } =
        this;
      this.$emit("saveBookOut", {
        deliver_from: deliver_from.getTime() / 1000,
        delivered_date: delivered_date ? delivered_date.getTime() / 1000 : null,
        teacher_id: teacher_id,
        comment: comment,
        lesson_id: lesson && lesson.id ? lesson.id : null,
      });
    },
  },
  watch: {
    delivered_date: function (newV) {
      if (newV) {
        this.lesson = undefined;
      }
    },
  },
};
</script>

<style>
</style>