<template>
  <div>
    <div class="title">
      Payments count: <strong>{{ payments.length }}</strong
      >, Paid:
      <strong>{{ amount }}</strong>
    </div>

    <div class="items">
      <div class="item" v-for="pay in payments" :key="pay.id">
        <div class="type">
          <vs-chip>
            <vs-avatar
              icon="people"
              v-if="pay.order.type == 'group'"
            ></vs-avatar>
            <vs-avatar
              icon="menu_book"
              v-if="pay.order.type == 'book'"
            ></vs-avatar>
            <vs-avatar
              icon="lesson"
              v-if="pay.order.type == 'school'"
            ></vs-avatar>
            <span v-if="pay.order.type == 'group'">
              Group: {{ pay.group.name }}
            </span></vs-chip
          >
        </div>
        <div class="pay-day">
          <vs-icon icon="query_builder" size="1.3rem"></vs-icon
          >{{ $moment(pay.pay_day * 1000).format("DD.MM.YYYY") }}
        </div>
        <div class="value">
          <div class="highlight">{{ pay.value }}р.</div>
        </div>
        <div class="payer" title="payer">
          <vs-chip>
            {{ pay.payer || "-" }}
          </vs-chip>
        </div>
        <div class="source">
          <vs-chip v-if="pay.source" color="#f1efc8">
            {{ pay.source.source_name }}
          </vs-chip>
          <vs-chip v-else color="#f1efc8"> - </vs-chip>
        </div>
        <div class="manage">
          <vs-button
            icon="delete"
            type="flat"
            color="dark"
            title="delete"
            @click="deleteHandle($event, pay.id)"
          ></vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["payments"],
  computed: {
    amount: function () {
      return this.payments
        .map((p) => parseInt(p.value, 10))
        .reduce((a, b) => a + b, 0);
    },
  },
  methods: {
    deleteHandle: function (e, id) {
      e.preventDefault();
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Delete payment",
        text:
          "Are you sure you want to delete the payment? This will recalculate orders in student",
        accept: function () {
          this.$store
            .dispatch("payments/deletePaymentAction", { id })
            .then(() => {
              this.$vs.notify({
                title: "Payment deleted",
                text: `Payment deleted`,
                color: "success",
                position: "top-right",
                time: 6000,
              });
              this.$emit("changedPayments");
            });
        }.bind(this),
      });
    },
  },
};
</script>

<style lang="scss">
.payment-table {
  width: 100%;
}
.payments__list {
  .title {
    margin: 0 0 5px 7px;
    font-size: 0.9rem;
    color: var(--font-color);
  }
}
.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  background-color: #f5f5f5;
  padding: 2px 10px;
  margin: 2px;
  box-shadow: 0 0 1px 1px #ddd;
  .value {
    margin-left: 15px;
    flex: 1;
    .highlight {
      font-size: 1.1em;
    }
  }
  .pay-day {
    display: flex;
    align-items: center;
    .vs-icon {
      margin-right: 7px;
    }
  }
}
</style>