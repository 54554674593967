import moment from 'moment'

export default function (group) {
    let currentYear = new Date().getFullYear();
    let months = [];
    let startDate = moment.unix(
        Math.min(group.schoolyear.date_start_unix, group.start_from || Infinity)
    );
    let endDate = moment.unix(
        Math.max(group.schoolyear.date_end_unix, group.end_to || 0)
    );
    while (startDate.isBefore(endDate)) {
        const month = {
            month: startDate.format("YYYY-MM"),
            current: parseInt(startDate.format("M"), 10),
            name:
                startDate.format("MMMM") +
                (startDate.year() != currentYear ? ` ${startDate.year()}` : ""),
            year: startDate.format("YYYY"),
            prev: parseInt(startDate.add(-1, "month").format("M"), 10),
            next: parseInt(startDate.add(2, "month").format("M"), 10)
        };
        months.push(month);
    }
    return months;
}