const examsOptions = [
  {
    name: 'FW',
  },
  {
    name: 'SB',
  },
  {
    name: 'QM',
  },
  {
    name: 'BT',
  },
  {
    name: 'L1',
  },
  {
    name: 'L2',
  },
  {
    name: 'L3',
  },
  {
    name: 'L4',
  },
]

export default examsOptions