<template>
  <div class="group_tabs">
    <vs-tabs>
      <vs-tab label="Attendance">
        <slot name="attendance"></slot>
      </vs-tab>
      <vs-tab label="Payments" v-if="$can('manage', 'payments')">
        <slot name="payments"></slot>
      </vs-tab>
      <vs-tab label="Books">
        <slot name="book-outs"></slot>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
</style>