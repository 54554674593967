<template>
  <div class="bookouts__wrapper">
    <div class="bookouts__title">
      <h2>
        <vs-icon icon="call_missed_outgoing"></vs-icon>
        Books give out
        <span v-if="bookOuts"> ({{ Object.keys(outs).length }}) </span>
      </h2>
    </div>
    <div class="bookouts__items">
      <book-outs-item
        :book_out="b"
        :groupStudents="students"
        v-for="(b, index) in Object.values(bookOuts)"
        :key="index"
        @updateGiveOuts="updateGiveOutsHandler"
      />

      <!-- <AttendanceTable :data="bookOuts" @cancelGiveOut="cancelGiveOutHandler" /> -->
      <div
        class="excluded"
        v-if="excludedBooksOut.rows && excludedBooksOut.rows.length"
      >
        <div class="excluded__title">
          <vs-button type="flat" @click="excludedShow = !excludedShow">
            + {{ excludedBooksOut.rows.length }} excluded students
          </vs-button>
        </div>
        <div class="excluded__attendanct-table" v-if="excludedShow">
          <AttendanceTable
            :data="excludedBooksOut"
            @cancelGiveOut="cancelGiveOutHandler"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AttendanceTable from "@/components/visits/attendanceTable";
import BookOutsItem from "./bookOutsItem.vue";
export default {
  name: "GroupBookOutsWrapper",
  props: ["group"],
  data() {
    return {
      excludedShow: false,
    };
  },
  components: { AttendanceTable, BookOutsItem },
  computed: {
    ...mapState({
      outs: (state) => state.groups.groupBookOuts,
      teachers: (state) => state.teachers.teachers,
    }),
    students: function () {
      return this.group.students.filter((s) => s.is_active);
    },
    excludedStudents: function () {
      return this.group.students.filter((s) => !s.is_active);
    },

    bookOuts: function () {
      let outs = Object.values({ ...this.outs }).map((book) => {
        let outsByStudentsObj = book.students.reduce((output, out) => {
          if (output[out.student.id] === undefined) {
            output[out.student.id] = {
              student: out.student,
              outs: [],
            };
          }
          output[out.student.id].outs.push(out);
          return output;
        }, {});
        book.students = Object.values(outsByStudentsObj);
        return book;
      });
      return outs;
    },
    excludedBooksOut: function () {
      if (!Object.keys(this.outs).length) return {};
      const data = {
        thead: [
          {
            value: "",
          },
        ],
        rows: this.excludedStudents.map((s) => {
          return {
            rowId: s.id,
            columns: [
              {
                value: s.name,
                link_to: {
                  name: "Student",
                  params: { student_id: s.id },
                },
              },
            ],
          };
        }),
      };
      for (let key in this.outs) {
        let book = this.outs[key];
        data.thead.push({
          value: book.name,
          link_to: {
            name: "Book",
            params: { book_id: book.id },
          },
        });
        if (book.students.length == 0) {
          data.rows.forEach((r) => {
            r.columns.push({
              value: "",
              status: "disabled",
            });
          });
        } else {
          data.rows.forEach((r) => {
            const studentInLesson = book.students.find((s) => s.id == r.rowId);
            if (studentInLesson) {
              r.columns.push({
                value: studentInLesson.delivered_date || "",
                edited: false,
                rowId: studentInLesson.id,
                columnId: studentInLesson.link_id,
                columnType: "bookOut",
                comment: studentInLesson.comment,
              });
            } else {
              r.columns.push({
                value: "",
                status: "disabled",
              });
            }
          });
        }
      }
      return data;
    },
  },
  methods: {
    getGroupBookOutsAction: function () {
      this.$store.dispatch("groups/getGroupBookOutsAction", {
        group_id: this.group.id,
      });
    },
    cancelGiveOutHandler: function (out_id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Give out book",
        text: "Are you sure you want to cancel give out this book",
        accept: () => {
          this.$store
            .dispatch("books/cancelBookOutsAction", {
              out_id,
            })
            .then(() => {
              this.$vs.notify({
                title: "Success",
                text: `Book give out cancelled`,
                color: "success",
                position: "top-right",
              });
              this.getGroupBookOutsAction();
            });
        },
      });
    },
    updateGiveOutsHandler: function () {
      this.getGroupBookOutsAction();
    },
  },

  created: function () {
    this.getGroupBookOutsAction();
    if (!this.teachers.length) {
      this.$store.dispatch("teachers/getTeachersAction");
    }
  },
};
</script>

<style lang="scss">
.bookouts {
  &__title {
    margin-bottom: 15px;
  }
}
</style>