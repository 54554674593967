<template>
  <div>
    <div class="visits">
      <MonthsSwitcher
        :schoolyear="group.schoolyear"
        @changedMonth="changedMonthHandle"
      >
        <template v-slot:stat>
          <div>
            To pay in {{ currentMonth.name }}:
            <strong>{{ ordersStat.orderGroupValue }}</strong>
          </div>
          <div>
            Plan lesson count:
            <strong>{{ ordersStat.curCountPlan }}</strong>
          </div>
        </template>
      </MonthsSwitcher>
      <div v-if="Object.values(orders).length">
        <OrderTable
          :group="group"
          :students="students"
          :orders="orders"
          :currentMonth="currentMonth"
          @changedPayments="changedPayments"
          @sendOrder="sendHandle"
        />
      </div>

      <div class="excluded" v-if="excludedStudents.length">
        <div class="excluded__title">
          <vs-button type="flat" @click="excludedShow = !excludedShow">
            + {{ excludedStudents.length }} excluded students
          </vs-button>
        </div>
        <div class="excluded__attendanct-table" v-if="excludedShow">
          <OrderTable
            :group="group"
            :students="excludedStudents"
            :orders="orders"
            :currentMonth="currentMonth"
            @sendOrder="sendHandle"
          />
        </div>
      </div>
    </div>
    <div class="group__stat__totals">
      <span>Total stat by group:</span>
      <totals-data :totals="totals" />
    </div>
  </div>
</template>

<script>
import MonthsSwitcher from "@/components/visits/monthsSwitcher";
import getOrdersStudents from "@/api/orders/getOrdersStudents.js";
import sendOrderStudents from "@/api/orders/sendOrderStudents.js";
import getStatTotals from "@/api/payments/getStatTotals";
import OrderTable from "@/components/groups/orders/table";
import TotalsData from "../../payments/statistic/totalsData.vue";

export default {
  props: ["group"],
  data() {
    return {
      orders: {},
      ordersStat: {},
      currentMonth: {},
      excludedShow: false,
      totals: {},
    };
  },
  components: { MonthsSwitcher, OrderTable, TotalsData },
  computed: {
    students: function () {
      return this.group.students.filter((s) => s.is_active);
    },
    excludedStudents: function () {
      return this.group.students.filter((s) => !s.is_active);
    },
  },
  methods: {
    changedMonthHandle: function (month) {
      this.currentMonth = month;
      this.setPage();
      this.getTotalStatByGroup();
    },
    getOrders: function () {
      getOrdersStudents
        .call(this, {
          group_id: this.group.id,
          schoolyear: this.group.schoolyear.id,
          month: this.currentMonth.month,
          year: this.currentMonth.year,
        })
        .then((data) => {
          this.produceOrders(data.data);
          this.ordersStat = data.meta;
          this.produceStat();
        });
    },
    produceOrders: function (orders) {
      this.orders = orders.reduce((a, x) => ({ ...a, [x.student.id]: x }), {});
    },
    produceStat: function () {
      const orders = Object.values(this.orders);
      if (orders.length) {
        const order = orders[0];
        this.ordersStat = {
          orderGroupValue: order.order_group_value,
          curCountPlan: order.lesson_cur_plan,
          ...this.ordersStat,
        };
      }
    },
    setPage() {
      this.getOrders();
    },
    changedPayments: function () {
      this.getOrders();
    },
    sendHandle: function (orderId) {
      const data = {
        id: orderId,
        sended: true,
      };
      sendOrderStudents.call(this, { data }).then((data) => {
        const order = data.data;
        this.orders[order.student.id] = order;
        this.$vs.notify({
          title: "Order marked as sended",
          text: ``,
          color: "success",
          position: "top-right",
        });
      });
    },
    getTotalStatByGroup: function () {
      const date = this.$moment()
        .set("month", this.currentMonth.month - 1)
        .set("year", this.currentMonth.year)
        .toDate();

      const query = {
        "filter.groups": [this.group.id],
        "filter.students": [],
        "filter.date_from": Math.floor(date.getTime() / 1000),
        "filter.date_to": Math.floor(date.getTime() / 1000),
      };
      getStatTotals.call(this, query).then((response) => {
        this.totals = response.data;
      });
    },
  },

  // mounted: function () {
  //   this.getTotalStatByGroup();
  // },
};
</script>

<style lang="scss">
.group__stat__totals {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  & > * {
    display: inline-block;
  }
}
</style>