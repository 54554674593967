<template>
  <div class="group-tests">
    <div class="book-tests" v-for="(book, index) in testData" :key="index">
      <div class="book-tests__header">
        <h3>
          Test results by <vs-icon icon="import_contacts"></vs-icon>
          {{ book.name }}
        </h3>
      </div>
      <attendance-table :data="book.data" class="tests" :withPopup="true" />
    </div>
    <div class="popup">
      <vs-button @click="popupActive = true" icon="functions" size="small"
        >Group progress</vs-button
      >
      <vs-popup
        class="form-popup"
        title="Group progress"
        :active.sync="popupActive"
      >
        <group-progress v-if="popupActive" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GroupProgress from "../results/groupProgress.vue";
import attendanceTable from "../visits/attendanceTable.vue";

export default {
  components: { attendanceTable, GroupProgress },
  name: "GroupTests",
  props: ["group"],
  data() {
    return {
      // testData: {},
      popupActive: false,
    };
  },
  computed: {
    ...mapState({
      groupResults: (state) => state.results.results,
      books: (state) => state.books.groupBooks,
    }),
    tests: function () {
      const testIds = [];
      this.results.forEach((r) => {
        if (!testIds.includes(r.test.id)) testIds.push(r.test.id);
      });

      return [...testIds].map((t) => {
        const result = this.results.find((r) => r.test.id == t);
        return result.test;
      });
    },
    results: function () {
      const results = [...this.groupResults];
      return results.sort((a, b) => a.index - b.index);
    },
    testData: function () {
      if (this.groupResults && this.books) return this.getTestData();
      else return [];
    },
  },
  methods: {
    getTestData: function () {
      let books = this.books
        .filter((b) => b.students.some((s) => s.with_tests != false))
        .map((book) => {
          const { name } = book;
          let data = {};
          const results = this.groupResults.filter((r) => r.book.id == book.id);
          const tests = results.reduce((list, r) => {
            if (!list.find((t) => t.id == r.test.id)) list.push(r.test);
            return list;
          }, []);

          tests.sort((a, b) => a.index - b.index);

          const bookUniqueStudentsObj = [...book.students].reduce(
            (output, s) => {
              output[s.id] = s;
              return output;
            },
            {},
          );

          if (results) {
            data = {
              thead: [
                {
                  value: "",
                },
                ...tests.map((t) => {
                  return { value: t.name };
                }),
              ],
              // rows: book.students
              rows: Object.values(bookUniqueStudentsObj)
                .filter((s) => {
                  let groupStudent = this.group.students.find(
                    (gs) => gs.id == s.id,
                  );
                  if (
                    groupStudent &&
                    groupStudent.is_active &&
                    s.with_tests != false
                  )
                    return s;
                })
                .sort((a, b) => {
                  if (a.name > b.name) {
                    return 1;
                  }
                  if (a.name < b.name) {
                    return -1;
                  }
                  // a должно быть равным b
                  return 0;
                })
                .map((s) => {
                  return {
                    rowId: s.id,
                    columns: [
                      {
                        value: s.name,
                        link_to: {
                          name: "Student",
                          params: { student_id: s.id },
                        },
                      },
                    ],
                  };
                }),
            };
            tests.forEach((test) => {
              const resultsInTest = results.filter((r) => r.test.id == test.id);
              data.rows.forEach((row) => {
                const result = resultsInTest.find(
                  (r) => r.student.id == row.rowId,
                );
                let status = null;
                if (result) {
                  if (result.persent < 40) status = "danger";

                  row.columns.push(this.getResultsData(result));
                } else {
                  status = "disabled";
                  row.columns.push({
                    value: "",
                    status,
                  });
                }
              });
            });
          }
          // Определяем время первого теста для сортировки учебника:
          // нужно, чтобы учебники с последними тестами отображались выше
          const minLessonTime = Math.min(
            ...results.map((r) => r.lesson?.lesson_time || r.test_time),
          );
          const output = {
            name,
            data,
            minLessonTime,
          };
          return output;
        });

      books.sort((a, b) => b.minLessonTime - a.minLessonTime);
      return books;
    },
    getResultsData: function (result) {
      return {
        value: `${result.value || "-"}/${result.test.max_value}`,
        sub: result.persent,
        unit: "%",
        link_to: { name: "Result", params: { result_id: result.id } },
        status,
        rowId: result.student.id,
        columnId: result.id,
        columnType: "test",
        popup: {
          book: result.book,
          test_time: result.test_time,
          test: result.test,
          lesson: result.lesson,
          owner: result.owner,
          comments: result.comments,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.group-tests {
  margin-top: 2rem;
}
.book-tests {
  margin-bottom: 10px;
  .book-tests__header {
    padding: 7px 0;
  }
}
</style>