<template>
  <div>
    <div class="books__add-button add-button" v-if="$can('manage', 'books')">
      <vs-button icon="add" type="flat" @click="popupActive = true"
        >Add Book</vs-button
      >
    </div>
    <div class="group-books__list">
      <div class="book-item" v-for="book in books" :key="book.id">
        <div class="book-item__header">
          <vs-icon icon="import_contacts" size="small"></vs-icon>
          <router-link
            :to="{ name: 'Book', params: { book_id: book.id } }"
            class="book-link"
          >
            {{ book.name }}
          </router-link>
          <div class="sub">Test count: {{ book.tests_count }}</div>
          <div class="group-book__item__edit" v-if="$can('manage', 'books')">
            <vs-button
              color="dark"
              type="flat"
              icon="edit"
              title="Edit book in group"
              size="small"
              @click="editBookHandler(book.id)"
              >Edit price</vs-button
            >
          </div>
          <div class="group-book__item__delete" v-if="$can('manage', 'books')">
            <vs-button
              color="dark"
              type="flat"
              icon="delete"
              title="Delete book from group"
              size="small"
              @click="deleteBookFromGroupHandle($event, book.id)"
            ></vs-button>
          </div>
          <div v-if="book.students.every(b => b.with_tests)">
            <vs-chip color="primary">
              <vs-icon icon="colorize" size="small"></vs-icon>With
              tests</vs-chip
            >
          </div>
        </div>
        <div
          class="book-item__students"
          v-for="(s, index) in book.students"
          :key="index"
        >
          <div class="book-student">
            <vs-icon icon="person" size="small"></vs-icon>
            <router-link
              :to="{ name: 'Student', params: { student_id: s.id } }"
              class="student-link"
            >
              {{ s.name }}
            </router-link>
          </div>
          <vs-chip class="book-type" v-if="s.book_in_out_type">{{
            s.book_in_out_type.name
          }}</vs-chip>
          <div class="book__price" v-if="$can('manage', 'payments')">
            <span>Price: {{ s.price || 0 }} р.</span>
          </div>
          <div
            class="book-with-tests"
            v-if="!book.students.every(b => b.with_tests) && s.with_tests"
          >
            <vs-chip color="primary">
              <vs-icon icon="colorize" size="small"></vs-icon>With
              tests</vs-chip
            >
          </div>
        </div>
      </div>
    </div>
    <vs-popup
      class="books-group__popup"
      classContent="books__popup-content"
      title="Add book to group"
      :active.sync="popupActive"
    >
      <add-book-to-item-form
        v-if="popupActive"
        :editBook="editBook"
        @closePopup="closePopup"
        @bookSaved="saved"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addBookToItemForm from '@/components/books/addBookToItemForm.vue'
import deleteBookToGroup from '@/api/books/deleteBookToGroup'

export default {
  components: { addBookToItemForm },
  // props: ["group_id", ],
  data() {
    return {
      deletedBookId: null,
      popupActive: false,
      editBook: {},
    }
  },
  computed: {
    ...mapState({
      group: state => state.groups.singleGroup,
      groups: state => state.groups.groups,
      books: state => state.books.groupBooks,
      years: state => state.years.years,
    }),
  },
  methods: {
    closePopup: function() {
      this.popupActive = false
      this.editBook = {}
    },
    saved: function(data) {
      const { group_id } = data
      this.reloadBooks({
        group_id,
      })
    },
    reloadBooks: function({ group_id }) {
      this.$store
        .dispatch('books/getGroupBooksAction', {
          group_id,
        })
        .then(() => this.closePopup())
    },
    deleteBookFromGroupHandle: function(e, book_id) {
      this.deletedBookId = book_id
      this.openAlert()
    },
    openAlert: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete book from group',
        text: 'Are you sure you want to delete the book from group?',
        accept: this.acceptAlert,
      })
    },
    acceptAlert: function() {
      deleteBookToGroup
        .call(this, this.group.id, this.deletedBookId)
        .then(() => this.reloadBooks({ group_id: this.group.id }))
    },
    editBookHandler: function(book_id) {
      const book = this.books.find(b => b.id == book_id)
      this.editBook = {
        book: book.id,
        selectedGroup: this.group.id,
        selectedStudents: book.students.map(s => s.id),
        price: 0,
        schoolyear_id: this.group.schoolyear_id,
      }
      this.popupActive = true
    },
  },
  created: function() {
    if (!this.books && this.group.id) {
      // const { group_id  = this;
      this.$store.dispatch('books/getBooksAction', { group_id: this.group.id })
    }
    if (!this.groups && this.group.id) {
      const data = {
        schoolyear_id: this.group.schoolyear.id,
      }
      this.$store.dispatch('groups/getGroupsAction', { data })
    }
    if (!this.years.length) {
      this.$store.dispatch('years/getYearsAction')
    }
  },
}
</script>

<style lang="scss">
.books-group__popup .vs-popup {
  width: 35%;
}
.book-item__header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 15px;
  font-size: 1.2em;
  margin: 15px 0 5px 15px;
  font-weight: bold;
  .sub {
    font-size: 0.7em;
  }
}
.book-item__students {
  display: flex;
  flex-flow: row nowrap;
  padding: 5px;
  margin: 3px 0 0 30px;
  gap: 15px;
  .book-student {
    flex: 1;
  }
}
.book-with-tests {
  vertical-align: middle;
  .vs-icon {
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
  }
  .text-chip {
    align-items: center;
  }
}
</style>
