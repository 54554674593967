<template>
  <div>
    <div class="edit-current-book__button">
      <vs-button
        icon="bookmarks"
        type="flat"
        size="small"
        @click="editBooksActive = true"
        >Edit group info</vs-button
      >
    </div>
    <div class="edit-current-book__popup">
      <vs-popup
        class="form-popup lesson__popup"
        classContent="popup-content"
        title="Edit group info"
        :active.sync="editBooksActive"
        v-if="editBooksActive"
      >
        <div class="field">
          <label for="">Book</label>
          <v-select
            label="name"
            :options="books"
            v-model="book"
            autocomplete="on"
            :reduce="
              item => {
                return {
                  id: item.id,
                  name: item.name,
                }
              }
            "
            clearable
            placeholder="Select book"
          >
            <template #option="{ name }">
              {{ name }}
            </template>
            <template #selected-option="{ name }">
              {{ name }}
            </template>
          </v-select>
        </div>
        <div class="field">
          <label for="">Reading</label>
          <v-select
            label="name"
            :options="books"
            v-model="reading"
            autocomplete="on"
            :reduce="
              item => {
                return {
                  id: item.id,
                  name: item.name,
                }
              }
            "
            clearable
            placeholder="Select book for reading"
          >
            <template #option="{ name }">
              {{ name }}
            </template>
            <template #selected-option="{ name }">
              {{ name }}
            </template>
          </v-select>
        </div>
        <div class="field">
          <label for="">IV</label>
          <v-select
            label="name"
            :options="verbOptions"
            v-model="verb"
            autocomplete="on"
            clearable
            placeholder="Select Irregular Verbs"
          >
            <template #option="{ name }">
              {{ name }}
            </template>
            <template #selected-option="{ name }">
              {{ name }}
            </template>
          </v-select>
        </div>
        <div class="field">
          <label for="">Exam</label>
          <v-select
            label="name"
            :options="examsOptions"
            v-model="exam"
            autocomplete="on"
            clearable
            placeholder="Select Exam"
          >
            <template #option="{ name }">
              {{ name }}
            </template>
            <template #selected-option="{ name }">
              {{ name }}
            </template>
          </v-select>
        </div>
        <div class="field">
          <vs-button icon="save" @click="saveHandler" class="save-button"
            >Save</vs-button
          >
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import IV from '@/components/common/dicts/IV.js'
import exams from '@/components/common/dicts/exams.js'

export default {
  data() {
    const book = {}
    const reading = {}
    const verb = {}
    const exam = {}

    return {
      editBooksActive: false,
      book,
      reading,
      verb,
      exam,
      verbOptions: IV,
      examsOptions: exams,
    }
  },
  components: {
    vSelect,
  },
  computed: {
    ...mapState({
      group: state => state.groups.singleGroup,
      books: state => state.books.groupBooks,
    }),
  },
  watch: {
    group: function() {
      this.book = this.group.current_books?.book
      this.reading = this.group.current_books?.reading
      this.verb = this.group.current_books?.verb
      this.exam = this.group.current_books?.exam
    },
  },
  methods: {
    closePopup: function() {
      this.editBooksActive = false
    },
    saveHandler: async function() {
      const { id } = this.group
      const { book, reading, verb, exam } = this
      const data = {
        book,
        reading,
        verb,
        exam,
      }
      await this.$store
        .dispatch('groups/saveGroupsAction', {
          groups: {
            id,
            current_books: data,
          },
        })
        .then(() => {
          this.closePopup()
        })
      await this.$store.dispatch('groups/getSingleGroupAction', {
        id,
      })
    },
  },
}
</script>

<style lang="scss">
.save-button {
  margin-bottom: 0.5rem;
  float: none !important;
}
</style>
