var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-lessons-list"},[_c('div',{staticClass:"group-lessons-table"},[_c('vs-table',{attrs:{"multiple":"","data":_vm.lessons,"hoverFlat":true,"notSpacer":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,class:{ crossed: tr.cancelled },attrs:{"data":tr}},[_c('vs-td',[(tr.is_lesson_ended)?_c('vs-icon',{attrs:{"icon":"done","color":"success"}}):_vm._e(),(tr.is_lesson_started && !tr.is_lesson_ended)?_c('vs-icon',{attrs:{"icon":"fiber_manual_record","color":"primary"}}):_vm._e()],1),_c('vs-td',{attrs:{"data":data[indextr].name}},[_c('router-link',{staticClass:"lesson-link",attrs:{"to":{
                name: 'Lessons',
                params: { lesson_id: data[indextr].id },
              }}},[_vm._v(" "+_vm._s(data[indextr].name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$moment(data[indextr].lesson_time * 1000).format( "DD MMMM YYYY HH:mm" ))+" ")])],1),_c('vs-td',{attrs:{"data":data[indextr].teacher_id}},[_c('div',{staticClass:"lesson-teacher"},[_c('vs-chip',{attrs:{"transparent":""}},[_c('vs-avatar',{attrs:{"src":tr.main_teacher.options && tr.main_teacher.options.avatar
                      ? (_vm.proxy + "/static/files/" + (tr.main_teacher.options.avatar))
                      : undefined}}),_vm._v(" "+_vm._s(tr.main_teacher.nickname)+" "),(
                    tr.main_teacher.date_start && !tr.main_teacher.date_end
                  )?_c('span',{staticClass:"lesson-in-work"},[_vm._v(" In work ")]):_vm._e(),(tr.main_teacher.date_end)?_c('span',{staticClass:"lesson-in-done"},[_vm._v(" Done ")]):_vm._e()],1)],1),_vm._l((data[indextr].other_teachers),function(t,key){return _c('div',{key:key,staticClass:"lesson-teacher"},[_c('vs-chip',{attrs:{"transparent":""}},[_c('vs-avatar',{attrs:{"src":t.options && t.options.avatar
                      ? (_vm.proxy + "/static/files/" + (t.options.avatar))
                      : undefined}}),_vm._v(" "+_vm._s(t.nickname)+" "),(t.date_start && !t.date_end)?_c('span',{staticClass:"lesson-in-work"},[_vm._v(" In work ")]):_vm._e(),(t.date_end)?_c('span',{staticClass:"lesson-in-done"},[_vm._v(" Done ")]):_vm._e()],1)],1)})],2),_c('vs-td',{attrs:{"data":data[indextr].assistants}},_vm._l((data[indextr].assistants),function(t,key){return _c('div',{key:key,staticClass:"lesson-teacher"},[_c('vs-chip',{attrs:{"transparent":""}},[_c('vs-avatar',{attrs:{"src":t.options && t.options.avatar
                      ? (_vm.proxy + "/static/files/" + (t.options.avatar))
                      : undefined}}),_vm._v(" "+_vm._s(t.nickname)+" "),(t.visited_at)?_c('span',{staticClass:"lesson-in-done"},[_vm._v(" Done ")]):_vm._e()],1)],1)}),0)],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"header"},[_vm._v(" Lessons ("+_vm._s(_vm.lessons.length)+") "),(_vm.selected.length && _vm.$can('manage', 'lesson'))?_c('span',[(_vm.savedCount)?_c('span',[_vm._v("Saved: "+_vm._s(_vm.savedCount))]):_vm._e(),_c('vs-button',{attrs:{"type":"flat","color":"primary"},on:{"click":function($event){_vm.batchTeacherPopypActive = true}}},[_vm._v("Set teachers")]),_c('vs-button',{attrs:{"type":"flat","color":"danger"},on:{"click":_vm.batchCancel}},[_vm._v("Cancel selected")])],1):_vm._e()]),_c('template',{slot:"thead"},[_c('vs-th'),_c('vs-th',[_vm._v(" Lesson name ")]),_c('vs-th',[_vm._v(" Lesson teachers ")]),_c('vs-th',[_vm._v(" Assistants ")])],1)],2)],1),(_vm.batchTeacherPopypActive)?_c('div',{staticClass:"popup-edit"},[(_vm.batchTeacherPopypActive)?_c('vs-popup',{staticClass:"form-popup edit-teachers__popup",attrs:{"classContent":"edit-teachers__popup-content","title":"Batch edit teachers","active":_vm.batchTeacherPopypActive},on:{"update:active":function($event){_vm.batchTeacherPopypActive=$event}}},[_c('batch-edit-teachers',{on:{"save":_vm.batchTeacherSaveHandler}})],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }