<template>
  <div>
    <form action="" @submit="submitHandler($event)">
      <div class="title">Add new payment</div>
      <div v-if="!student">
        <vs-select class="field" label="Student" v-model="student_id">
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in students"
          />
        </vs-select>
      </div>
      <div v-if="order_type == 'OrdersStudentsGroup' && !group">
        <vs-select class="field" label="group" v-model="group_id">
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in groups"
          />
        </vs-select>
      </div>
      <div v-if="order_type == 'OrdersStudentsLesson' && !lesson">
        <vs-select class="field" label="lesson" v-model="lesson_id">
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in tutorLessons"
          />
        </vs-select>
      </div>
      <div v-if="!order">
        <vs-select class="field" label="Pay type" v-model="order_type">
          <vs-select-item :key="1" value="group" text="group" />
          <vs-select-item :key="2" value="book" text="book" />
          <vs-select-item :key="3" value="lesson" text="lesson" />
        </vs-select>
        <vs-select class="field" label="Pay Month" v-model="month">
          <vs-select-item
            :key="index"
            :value="index"
            :text="item"
            v-for="(item, index) in $moment.months()"
          />
        </vs-select>
      </div>

      <vs-input
        ref="value"
        label="Value"
        v-model="value"
        class="field"
      ></vs-input>
      <vs-select class="field" label="Payment source" v-model="source_id">
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.source_name"
          v-for="(item, index) in sources"
        />
      </vs-select>
      <div class="datepicker field">
        <label for="">Pay day</label>
        <v-date-picker v-model="pay_day" />
      </div>
      <vs-input label="Payer" v-model="payer" class="field"></vs-input>

      <vs-button icon="save" @click="submitHandler($event)">Save</vs-button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["order", "student", "group", "lesson", "schoolyear"],
  data() {
    const date = new Date();

    return {
      value: "",
      student_id: this.student ? this.student.id : null,
      group_id: this.group ? this.group.id : null,
      lesson_id: this.lesson ? this.lesson.id : null,
      //   schoolyear: this.group.schoolyear.id,
      source_id: null,
      pay_day: date,
      payer: "",
      order_type: this.order ? this.order.order_type : "OrdersStudentsGroup",
      month: date.getMonth(),
      //   year: date.getFullYear(),
    };
  },
  computed: {
    ...mapState({
      sources: (state) => state.payments.sources,
      students: (state) => state.students.students,
      groups: (state) => state.groups.groups,
      year: (state) => state.years.currentSchoolYear,
      tutorLessons: (state) => state.lessons.tutorLessonsByStudent,
    }),
  },
  methods: {
    submitHandler: function (e) {
      e.preventDefault();
      //   TODO: get group from order
      const { student_id, pay_day, payer, value, source_id } = this;

      const data = {
        student_id,
        value,
        payer,
        source_id,
        pay_day: pay_day.getTime() / 1000,
        order_id: this.order.id,
        order_type: this.order.type,
        schoolyear_id: this.group
          ? this.group.schoolyear.id
          : this.order.schoolyear_id
          ? this.order.schoolyear_id
          : null,
      };

      if (this.group_id) data.group_id = this.group_id;
      if (this.book_id) data.book_id = this.book_id;
      // if (this.order.type == 'OrdersStudentsLesson'){
      //   data.order_id = this.order.id,
      //   data.order_type = this.order.type
      // }
      this.$store
        .dispatch("payments/setOrderPaymentAction", { payment: data })
        .then((response) => {
          if (response.errors && response.errors.length) {
            response.errors.forEach((e) => {
              this.$vs.notify({
                title: "Payment not saved!",
                text: `${e.message}`,
                color: "danger",
                position: "top-right",
              });
            });
            return;
          }
          this.$vs.notify({
            title: "Payment saved!",
            text: ``,
            color: "success",
            position: "top-right",
          });
          this.$store.dispatch("students/getSingleStudentAction", {
            id: student_id,
          });
          this.$emit("changedPayments");
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Payment not saved!",
            text: `${e}`,
            color: "danger",
            position: "top-right",
          });
        });
    },
  },
  created: function () {
    if (this.students.length == 0) {
      this.$store.dispatch("students/getStudentsAction");
    }
    if (this.groups.length == 0) {
      this.$store.dispatch("groups/getGroupsAction");
    }
    if (this.sources.length == 0) {
      this.$store.dispatch("payments/getSourcesAction").then((data) => {
        if (data.length) {
          this.source_id = data[0].id;
        }
      });
    } else {
      this.source_id = this.sources[0].id;
    }
    setTimeout(() => {
      this.$refs.value.focusInput();
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
form {
  padding: 20px 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px 3px #eee;
  button {
    margin-top: 15px;
    float: none !important;
  }
}
.field {
  width: 100%;
}
</style>