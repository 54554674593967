<template>
  <div class="batch-teacher-edit">
    <lesson-other-teacher-form @changeTeachers="changeTeachersHandler" />
    <div class="save-button">
      <vs-button @click="$emit('save', teachers)">Save</vs-button>
    </div>
  </div>
</template>

<script>
import lessonOtherTeacherForm from "../../lessons/lessonOtherTeacherForm.vue";
export default {
  data() {
    return {
      teachers: [],
    };
  },
  components: { lessonOtherTeacherForm },
  methods: {
    changeTeachersHandler: function (teachers) {
      this.teachers = teachers;
    },
  },
};
</script>

<style>
</style>