<template>
  <div class="visits">
    <div class="visits__months">
      <LessonStatistic :statistic="prevMonthStat" />
      <div class="prev">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_left"
          @click="downMonth"
        ></vs-button>
      </div>
      <div class="month-name">{{ current.name }}</div>
      <div class="next">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_right"
          @click="upMonth"
        ></vs-button>
      </div>
    </div>
    <AttendanceTable :data="data" />
    <div
      class="excluded"
      v-if="excludedStudentsData.rows && excludedStudentsData.rows.length"
    >
      <div class="excluded__title">
        <vs-button type="flat" @click="excludedShow = !excludedShow">
          + {{ excludedStudentsData.rows.length }} excluded students
        </vs-button>
      </div>
      <div class="excluded__attendanct-table" v-if="excludedShow">
        <AttendanceTable :data="excludedStudentsData" />
      </div>
    </div>
    <div class="current-static">
      <div class="current-static__item">
        <span>Всего занятий запланировано в этом месяце:</span>
        <span>
          <strong>{{ currentStatic.planLessonCount || '-' }}</strong>
        </span>
      </div>
      <div class="current-static__item">
        <span>Всего занятий проведено в этом месяце:</span>
        <span>
          <strong>{{ currentStatic.doneLessonCount || '-' }}</strong>
        </span>
      </div>
      <div class="current-static__item">
        <span>Всего занятий отменено в этом месяце:</span>
        <span>
          <strong>{{ currentStatic.cancelledCount || '-' }}</strong>
        </span>
      </div>
      <div class="current-static__item">
        <span>Всего занятий проведено:</span>
        <span>
          <strong>{{ currentStatic.doneAllLessonCount || '-' }}</strong>
        </span>
      </div>
      <div class="current-static__item">
        <span>Всего учеников:</span>
        <span>
          <strong>{{ currentStatic.studentCount || '-' }}</strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AttendanceTable from '@/components/visits/attendanceTable'
import getSchoolYearMonths from '@/plugins/schoolYears'
import LessonStatistic from '@/components/lessons/lessonMonthStat'

export default {
  name: 'visits',
  props: ['group'],
  data() {
    return {
      data: {},
      excludedStudentsData: {},
      pages: [],
      current: {},
      today: new Date(),
      prevMonthStat: {},
      currentStatic: {},
      excludedShow: false,
    }
  },
  components: { AttendanceTable, LessonStatistic },
  computed: {
    ...mapState({
      lessons: state => state.lessons.lessons,
    }),
  },
  methods: {
    getLessonsByGroup: function() {
      return this.$store.dispatch('lessons/getLessonsByGroupsAction', {
        group_id: this.group.id,
      })
    },
    getAttendancePages: function() {
      if (!this.group.schoolyear) return
      let months = getSchoolYearMonths(this.group)
      this.pages = months.map((m, index) => {
        const monthObject = this.$moment(m.month, 'YYYY-MM')
        const page = {
          name: m.name,
          index,
          current:
            monthObject.month() == this.today.getMonth() &&
            monthObject.year() == this.today.getFullYear(),
          month: m.current,
          monthObject,
        }
        if (page.current) this.current = page
        return page
      })
      if (typeof this.current.index == 'undefined') {
        const schoolYearStartDate = new Date(
          this.group.schoolyear.date_start_unix * 1000
        )
        if (schoolYearStartDate.getMonth() + 1 == this.pages[0].month) {
          this.current = this.pages[0]
        } else {
          this.current = this.pages[this.pages.length - 1]
        }
      }
    },
    getAttendanceLessons: function() {
      const lessons = this.lessons.filter(
        l => new Date(l.lesson_time * 1000).getMonth() + 1 == this.current.month
      )
      this.currentStatic = {
        planLessonCount: lessons.length,
        doneLessonCount: lessons.filter(l => l.date_end).length,
        cancelledCount: lessons.filter(l => l.cancelled).length,
        studentCount: this.group.students.filter(s => s.is_active).length,
      }
      // all lessons
      const nextMonth = this.current.monthObject
        .clone()
        .add(1, 'M')
        .startOf('month')
        .toDate()
      this.currentStatic.doneAllLessonCount = this.lessons.filter(
        l => l.date_end && new Date(l.lesson_time * 1000) < nextMonth
      ).length
      const excludedStudentsData = {
        thead: [
          {
            value: '',
          },
        ],
        rows: this.group.students
          .filter(s => {
            if (s.is_active == false) return s
          })
          .map(s => {
            return {
              rowId: s.id,
              columns: [
                {
                  value: s.name,
                  link_to: {
                    name: 'Student',
                    params: { student_id: s.id },
                  },
                },
              ],
            }
          }),
      }

      const data = {
        thead: [
          {
            value: '',
          },
        ],
        rows: this.group.students
          .filter(s => {
            if (s.is_active != false) return s
          })
          .map(s => {
            return {
              rowId: s.id,
              columns: [
                {
                  value: s.name,
                  is_trial: s.is_trial,
                  link_to: {
                    name: 'Student',
                    params: { student_id: s.id },
                  },
                },
              ],
            }
          }),
      }
      this.data = this.getLessons(data, lessons)
      this.excludedStudentsData = this.getLessons(excludedStudentsData, lessons)
    },
    getLessons: function(data, lessons) {
      lessons.forEach(l => {
        let status = null
        if (l.cancelled) status = 'cancelled'

        data.thead.push({
          value: new Date(l.lesson_time * 1000).getDate(),
          link_to: {
            name: 'Lessons',
            params: { lesson_id: l.id },
          },
          status,
          icon: l.date_end ? 'checked' : null,
          leftIcon: l.options && l.options.tests ? 'test' : null,
          topLeftIcon: l.outs ? 'import_contacts' : null,
          topRightIcon: l.is_online ? 'online_prediction' : null,
        })
        if (l.students.length == 0) {
          data.rows.forEach(r => {
            r.columns.push({
              value: '',
              status,
            })
          })
        } else {
          data.rows.forEach(r => {
            const studentInLesson = l.students.find(s => s.id == r.rowId)
            if (studentInLesson) {
              switch (studentInLesson.exist) {
                case 'б':
                  status = 'wrong'
                  break
                case '0':
                  status = 'empty'
                  break
                case 'н':
                  status = 'danger'
                  break
                case 'ч':
                  status = 'warn'
                  break
                default:
                  status = null
              }
              r.columns.push({
                value: studentInLesson.exist || '',
                status,
                edited: false,
                rowId: studentInLesson.id,
                columnId: studentInLesson.link_id,
                columnType: 'lesson',
                comment: studentInLesson.comment,
                surprises: studentInLesson.surprises,
                is_trial: studentInLesson.is_trial,
              })
            } else {
              status = 'disabled'
              r.columns.push({
                value: '',
                status,
              })
            }
          })
        }
      })
      return data
    },
    getStatOfPrevMonth: function() {
      const prevMonth = this.pages[this.current.index - 1]
      if (prevMonth) {
        const prevMonthLessons = this.lessons.filter(
          l => new Date(l.lesson_time * 1000).getMonth() + 1 == prevMonth.month
        )
        let allCount = prevMonthLessons.length,
          cancelledCount = 0,
          doneCount = 0

        cancelledCount = prevMonthLessons.reduce(
          (a, b) => (b.cancelled ? a + 1 : a),
          0
        )
        doneCount = prevMonthLessons.reduce(
          (a, b) => (b.date_end ? a + 1 : a),
          0
        )
        this.prevMonthStat = {
          monthName: prevMonth.name,
          allCount,
          doneCount,
          cancelledCount,
        }
      } else {
        this.prevMonthStat = {}
      }
    },
    downMonth: function() {
      const index = this.current.index
      if (index > 0) {
        this.current = this.pages[index - 1]
        this.getAttendanceLessons()
        this.getStatOfPrevMonth()
      }
    },
    upMonth: function() {
      const index = this.current.index
      if (index + 1 < this.pages.length) {
        this.current = this.pages[index + 1]
        this.getAttendanceLessons()
        this.getStatOfPrevMonth()
      }
    },
    setPage() {
      this.getAttendancePages()
      this.getLessonsByGroup().then(() => {
        this.getAttendanceLessons()
        this.getStatOfPrevMonth()
      })
    },
  },
  watch: {
    group: function(newV) {
      if (newV) {
        this.setPage()
      }
    },
  },
  mounted: function() {
    this.setPage()
  },
}
</script>
<style lang="scss">
.excluded__attendanct-table {
  margin: 15px 0;
}
</style>
