<template>
  <div class="result">
    <div class="result__info">
      <div class="result__info__date">
        <vs-icon icon="query_builder"></vs-icon>
        {{ $moment(data.test_time * 1000).format("DD.MM.YYYY") }}
      </div>
      <div class="result__info__lesson">
        <router-link
          :to="{ name: 'Lessons', params: { lesson_id: data.lesson.id } }"
          v-if="data.lesson"
        >
          {{ data.lesson.name }}
        </router-link>
      </div>
      <div class="result__info__book">
        <router-link
          :to="{ name: 'Book', params: { book_id: data.book.id } }"
          v-if="data.book"
        >
          <vs-icon size="18px" icon="import_contacts"></vs-icon>
          {{ data.book.name }}
        </router-link>
        <sub v-else-if="data.type == 'TestsIncomeResult'">{{
          data.test.name || "-"
        }}</sub>
        <sub v-else class="sub">No book</sub>
      </div>
      <div class="result__info__teacher" v-if="resultTeacher">
        <vs-avatar
          v-if="resultTeacher"
          :src="
            resultTeacher.options && resultTeacher.options.avatar
              ? `${proxy}/static/files/${resultTeacher.options.avatar}`
              : undefined
          "
          size="small"
        ></vs-avatar
        >{{ resultTeacher.nickname }}
      </div>
    </div>
    <div class="result__comments">
      <div
        class="result__comment"
        v-for="(comment, index) in data.comments"
        :key="index"
      >
        <div class="result__comment__header">
          <vs-avatar
            size="small"
            v-if="comment.teacher"
            :src="
              comment.teacher.options && comment.teacher.options.avatar
                ? `${proxy}/static/files/${comment.teacher.options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ comment.teacher.nickname }}
          <div class="result__comment__date">
            {{ $moment.unix(comment.created).format("DD MM YYYY HH:mm") }}
          </div>
        </div>
        <div
          style="white-space: pre-line"
          v-html="comment.comment"
          class="result__comment__body"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    };
  },
  computed: {
    resultTeacher: function () {
      if (this.data.type == "TestsIncomeResult") return this.data.user;
      return this.data.owner;
    },
  },
};
</script>

<style lang="scss">
.result__info {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  color: var(--font-color);
  & > div {
    width: 45%;
    padding: 0.5rem;
    & > * {
      vertical-align: middle;
    }
  }
  .result__info__lesson,
  .result__info__date {
    font-size: 0.8em;
    i {
      font-size: 1em;
    }
  }
}
.result {
  &__comment {
    padding: 0.5rem;
    margin: 0.5rem;
    border-top: 1px solid #ccc;
    &__header {
      display: flex;
      flex-flow: row nowrap;
      justify-items: center;
      align-items: center;
    }
    &__date {
      margin-left: 1.5rem;
      font-size: 0.8rem;
      color: var(--sub-color);
    }
    &__body {
      padding: 0.5rem;
    }
  }
}
.result-tooltip {
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 5px 30px rgba(black, 0.1);
  padding: 10px;
  width: 400px;
}
</style>