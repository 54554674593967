var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"book-out-form"},[_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Teacher")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select book"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var nickname = ref.nickname;
var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.teacher_id),callback:function ($$v) {_vm.teacher_id=$$v},expression:"teacher_id"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Give out from")]),_c('v-date-picker',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var inputValue = ref.inputValue;
              var inputEvents = ref.inputEvents;
return [_c('div',[_c('vs-input',_vm._g({staticClass:"calendar-input",attrs:{"value":("" + inputValue),"size":"large"}},inputEvents))],1)]}}]),model:{value:(_vm.deliver_from),callback:function ($$v) {_vm.deliver_from=$$v},expression:"deliver_from"}})],1),(_vm.delivered_date)?_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Was given")]),_c('v-date-picker',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var inputValue = ref.inputValue;
              var inputEvents = ref.inputEvents;
return [_c('div',[_c('vs-input',_vm._g({staticClass:"calendar-input",attrs:{"value":("" + inputValue),"size":"large"}},inputEvents)),(_vm.lesson)?_c('div',[_vm._v("("+_vm._s(_vm.lesson.name)+")")]):_vm._e()],1)]}}],null,false,712658319),model:{value:(_vm.delivered_date),callback:function ($$v) {_vm.delivered_date=$$v},expression:"delivered_date"}})],1):_vm._e(),_c('div',{staticClass:"field"},[_c('vs-textarea',{attrs:{"label":"Comment"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('vs-button',{attrs:{"icon":"save"},on:{"click":_vm.save}},[_vm._v("Save")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }