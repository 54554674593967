<template>
  <div class="book-out-form">
    <div class="field">
      <label for="">Give out from</label>
      <v-date-picker v-model="deliver_from">
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <vs-input
              class="calendar-input"
              :value="`${inputValue}`"
              v-on="inputEvents"
              size="large"
            />
          </div>
        </template>
      </v-date-picker>
    </div>
    <vs-button icon="save" @click="save">Save</vs-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deliver_from: new Date(),
    };
  },
  methods: {
    save: function () {
      const { deliver_from } = this;
      this.$emit("saveBookOut", {
        deliver_from: deliver_from.getTime() / 1000,
      });
    },
  },
};
</script>

<style>
</style>