var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"edit-current-book__button"},[_c('vs-button',{attrs:{"icon":"bookmarks","type":"flat","size":"small"},on:{"click":function($event){_vm.editBooksActive = true}}},[_vm._v("Edit group info")])],1),_c('div',{staticClass:"edit-current-book__popup"},[(_vm.editBooksActive)?_c('vs-popup',{staticClass:"form-popup lesson__popup",attrs:{"classContent":"popup-content","title":"Edit group info","active":_vm.editBooksActive},on:{"update:active":function($event){_vm.editBooksActive=$event}}},[_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Book")]),_c('v-select',{attrs:{"label":"name","options":_vm.books,"autocomplete":"on","reduce":function (item) {
              return {
                id: item.id,
                name: item.name,
              }
            },"clearable":"","placeholder":"Select book"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,2867049997),model:{value:(_vm.book),callback:function ($$v) {_vm.book=$$v},expression:"book"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Reading")]),_c('v-select',{attrs:{"label":"name","options":_vm.books,"autocomplete":"on","reduce":function (item) {
              return {
                id: item.id,
                name: item.name,
              }
            },"clearable":"","placeholder":"Select book for reading"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,2867049997),model:{value:(_vm.reading),callback:function ($$v) {_vm.reading=$$v},expression:"reading"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("IV")]),_c('v-select',{attrs:{"label":"name","options":_vm.verbOptions,"autocomplete":"on","clearable":"","placeholder":"Select Irregular Verbs"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,2867049997),model:{value:(_vm.verb),callback:function ($$v) {_vm.verb=$$v},expression:"verb"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Exam")]),_c('v-select',{attrs:{"label":"name","options":_vm.examsOptions,"autocomplete":"on","clearable":"","placeholder":"Select Exam"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
            var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,2867049997),model:{value:(_vm.exam),callback:function ($$v) {_vm.exam=$$v},expression:"exam"}})],1),_c('div',{staticClass:"field"},[_c('vs-button',{staticClass:"save-button",attrs:{"icon":"save"},on:{"click":_vm.saveHandler}},[_vm._v("Save")])],1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }