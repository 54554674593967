<template>
  <div class="payments">
    <div class="payments__form">
      <PaymentForm
        :student="student"
        :group="group"
        :lesson="lesson"
        :order="order"
        @changedPayments="$emit('changedPayments')"
      />
    </div>
    <div class="payments__list">
      <slot name="order"></slot>
      <div class="items-wrapper">
        <PaymentList
          :payments="payments"
          @changedPayments="$emit('changedPayments')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import PaymentForm from "@/components/payments/singleForm/form";
import PaymentList from "@/components/payments/singleForm/list";
export default {
  props: ["student", "group", "lesson", "order"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      payments: (state) => state.payments.orderPayments,
    }),
  },
  components: {
    PaymentForm,
    PaymentList,
  },
  created: function () {
    const query = {};
    if (this.student) query.student_id = this.student.id;
    if (this.group) query.group_id = this.group.id;
    if (this.order) query.order_id = this.order.id;
    if (this.lesson) query.lessons_id = this.lesson.id;

    this.$store.dispatch("payments/getOrderPaymentsAction", query);
    this.$store.dispatch("payments/getSourcesAction");
  },
};
</script>

<style lang="scss">
.payments {
  display: flex;
  flex-flow: row nowrap;
  .payments__form {
    width: 32%;
    padding: 0 1em;
  }
  .items-wrapper {
    margin-top: 15px;
    padding-top: 25px;
    border-top: 1px solid #ccc;
  }
  .payments__list {
    overflow-y: auto;
    width: 65%;
  }
}
</style>