const verbOptions = [
    {
      name: 'IV-1',
    },
    {
      name: 'IV-2',
    },
    {
      name: 'IV-3',
    },
    {
      name: 'IV-4',
    },
  ]

export default verbOptions