<template>
  <div>
    <div class="progress__group">
      <h3>Group average progress:</h3>
      <vs-progress
        :percent="groupAverage"
        :color="getColor(groupAverage)"
        opacity="0.5"
        height="16"
        class="test-progress"
        >{{ groupAverage.toFixed(2) }}</vs-progress
      >
      <span
        class="progress__group__result"
        :class="{
          high: groupAverage >= 85,
          subhigh: groupAverage >= 75 && groupAverage < 85,
          middle: groupAverage >= 50 && groupAverage < 75,
          low: groupAverage < 50,
        }"
      >
        {{ groupAverage.toFixed(2) }} %
      </span>
    </div>
    <div class="progress__books">
      <h3>Books average progress:</h3>

      <div
        class="progress__books__book"
        v-for="(book, index) in booksAverage"
        :key="index"
      >
        <div class="book__name">
          <vs-icon icon="import_contacts"></vs-icon>
          <span>{{ book.name }}</span>
        </div>
        <vs-progress
          :percent="book.bookAverage"
          :color="getColor(book.bookAverage)"
          height="12"
          class="test-progress"
          >{{ book.bookAverage.toFixed(2) }}</vs-progress
        >
        {{ book.bookAverage.toFixed(2) }} %
      </div>
    </div>
    <!-- {{ chartData }}-->
    <div class="progress__tests">
      <h3>Tests average progress:</h3>
      <canvas id="my-chart"></canvas>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Chart from "chart.js/auto";

export default {
  data() {
    return {
      chart: {},
    };
  },
  computed: {
    ...mapState({
      groupResults: (state) => state.results.results,
      books: (state) => state.books.groupBooks,
      group: (state) => state.groups.singleGroup,
    }),
    groupAverage: function () {
      const initialVals = { avg: 0, n: 0 };
      return this.groupResults.reduce(({ avg, n }, result) => {
        if (!result.persent) return { avg, n };
        return {
          avg: (result.persent + n * avg) / (n + 1),
          n: n + 1,
        };
      }, initialVals).avg;
    },
    booksAverage: function () {
      return this.books
        .filter((book) => book.tests_count)
        .map((book) => {
          const { id, name } = book;
          let tests = this.groupResults.filter(
            (result) => result.book.id == book.id,
          );
          const initialVals = { avg: 0, n: 0 };
          return {
            id,
            name,
            bookAverage: tests.reduce(({ avg, n }, result) => {
              if (!result.persent) return { avg, n };
              return {
                avg: (result.persent + n * avg) / (n + 1),
                n: n + 1,
              };
            }, initialVals).avg,
          };
        });
    },
    testAverage: function () {
      let tests = this.groupResults.reduce((tests, result) => {
        const { lesson } = result;
        if (!tests[result.test.id]) {
          const { test, book } = result;
          tests[result.test.id] = {
            name: test.name,
            book: book.name,
            results: { avg: 0, n: 0 },
            testTime: undefined,
            r: [],
          };
        }
        // берем время занятия
        tests[result.test.id].testTime = lesson?.lesson_time;
        if (result.persent) {
          tests[result.test.id].r.push(result.persent);
          tests[result.test.id].results = {
            avg:
              (result.persent +
                tests[result.test.id].results.n *
                  tests[result.test.id].results.avg) /
              (tests[result.test.id].results.n + 1),
            n: tests[result.test.id].results.n + 1,
          };
        }
        return tests;
      }, {});

      tests = Object.values(tests);
      tests.sort((a, b) => a.testTime - b.testTime);
      return tests;
    },
  },
  methods: {
    getColor: function (value) {
      if (value >= 85) return "success";
      if (value < 85 && value >= 75) return "dark";
      if (value < 75 && value >= 50) return "warning";
      return "danger";
    },
  },
  mounted() {
    let element = document.getElementById("my-chart");
    this.chart = new Chart(element, {
      type: "line",
      data: {
        labels: this.testAverage.map((t) => t.name),
        datasets: [
          {
            label: this.group.name,
            data: this.testAverage.map((t) => t.results.avg),
            backgroundColor: this.group.color,
            borderColor: this.group.color,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
      },
    });
  },
  beforeDestroy() {
    this.chart.destroy();
  },
};
</script>

<style lang="scss">
.progress {
  &__group {
    padding: 1rem;
  }
  &__tests {
    padding: 1rem;
  }
  &__books {
    padding: 1rem;

    &__book {
      padding: 1rem;
      border-bottom: 1px solid var(--element-border-color);
      .book__name {
        align-items: center;
        display: flex;
      }
    }
  }
}
.test-progress {
  .vs-progress--foreground {
    opacity: 0.4 !important;
  }
}
</style>