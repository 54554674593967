<template>
  <div class="book-out">
    <div class="book-out-header">
      <h3 class="book-out-header__title">
        <vs-icon icon="import_contacts" color="dark"></vs-icon>
        <router-link :to="{ name: 'Book', params: { book_id: book_out.id } }">{{
          book_out.name
        }}</router-link>
      </h3>
      <div class="book-out-header__clear" v-if="$can('manage', 'book_outs')">
        <vs-button
          icon="clear"
          color="dark"
          size="small"
          type="flat"
          @click="clearHandler()"
          >Clear All Give out</vs-button
        >
      </div>
      <div
        class="book-out-header__change-date"
        v-if="$can('manage', 'book_outs')"
      >
        <vs-button
          icon="date_range"
          color="primary"
          size="small"
          type="flat"
          @click="editAllHandler"
          >Change date</vs-button
        >
      </div>
      <div
        class="book-out-header__change-date"
        v-if="$can('manage', 'book_outs')"
      >
        <vs-button
          icon="delete"
          color="danger"
          size="small"
          type="flat"
          @click="deleteHandler()"
          >Delete All from give out</vs-button
        >
      </div>
    </div>
    <div class="book-out-students">
      <div
        class="book-out-students__item"
        v-for="student in students"
        :key="student.student.id"
      >
        <div class="book-out-students__item__name">
          <!-- <div
            class="not-delivered-flag"
            v-if="!student.delivered_date"
            title="Book not received"
          ></div>
          <vs-avatar icon="person"></vs-avatar>
          <span :class="{ 'not-delivered': !student.delivered_date }">{{
            student.student.name
          }}</span> -->
          <vs-avatar icon="person"></vs-avatar>
          <span>
            {{ student.student.name }}
          </span>
        </div>
        <div class="book-out-students__item__gived-items">
          <div
            class="book-out-students__item__gived"
            v-for="s in student.outs"
            :key="s.id"
          >
            <div v-if="s.delivered_date">
              <vs-icon color="success" icon="done"></vs-icon>
              <div class="book-out-students__item__lesson-name">
                <router-link
                  :to="{ name: 'Lessons', params: { lesson_id: s.lesson.id } }"
                  v-if="s.lesson"
                >
                  {{ s.lesson.name }}
                </router-link>
                <div class="book-out-students__item__delivered-date">
                  <vs-avatar
                    size="small"
                    :src="
                      s.teacher.avatar
                        ? `${proxy}/static/files/${s.teacher.avatar}`
                        : undefined
                    "
                  ></vs-avatar>
                  {{ s.teacher.nickname }}
                  <span>-</span>
                  {{ $moment(s.delivered_date * 1000).format("DD.MM.YYYY") }}
                </div>
              </div>
              <vs-button
                icon="clear"
                type="flat"
                color="dark"
                size="small"
                title="Clear give out"
                @click="clearHandler(s.id)"
                v-if="$can('manage', 'book_outs')"
              ></vs-button>
            </div>
            <div v-else-if="s.id && s.near_lesson">
              <div>
                Plan to give out on
                <router-link
                  :to="{
                    name: 'Lessons',
                    params: { lesson_id: s.near_lesson.id },
                  }"
                >
                  {{ s.near_lesson.name }}
                </router-link>
                <div class="book-out-students__item__delivered-date">
                  {{
                    $moment(s.near_lesson.lesson_time * 1000).format(
                      "DD.MM.YYYY",
                    )
                  }}
                </div>
              </div>
            </div>
            <div v-else></div>

            <div class="book-out-students__item__comment">
              {{ s.comment }}
            </div>
            <div
              class="book-out-students__item__delete"
              v-if="$can('manage', 'book_outs')"
            >
              <vs-button
                icon="delete"
                color="danger"
                size="small"
                type="flat"
                @click="deleteHandler(s.id)"
                v-if="student.student.id"
                >Delete</vs-button
              >
            </div>
            <div
              class="book-out-students__item__edit"
              v-if="$can('manage', 'book_outs')"
            >
              <vs-button
                icon="delete"
                color="primary"
                size="small"
                type="flat"
                @click="editSingleHandler(s)"
                v-if="student.student.id"
                >Edit</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="popup-single-book-out"
      v-if="$can('manage', 'books_outs') && editPopupActive"
    >
      <vs-popup
        class="form-popup lesson__popup"
        classContent="lesson__popup-content"
        title="Edit book outs"
        :active.sync="editPopupActive"
        v-if="editPopupActive"
      >
        <single-book-out-form
          :out="selectedOut"
          @closePopup="closePopup"
          @saveBookOut="saveSingleOutHandler"
        />
      </vs-popup>
    </div>
    <div
      class="popup-multi-book-out"
      v-if="$can('manage', 'books_outs') && editMultiActive"
    >
      <vs-popup
        class="form-popup lesson__popup"
        classContent="lesson__popup-content"
        title="Edit book outs"
        :active.sync="editMultiActive"
        v-if="editMultiActive"
      >
        <multi-book-out-form
          @closePopup="closePopup"
          @saveBookOut="saveMultipleOutHandler"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import MultiBookOutForm from "./multiBookOutForm.vue";
import singleBookOutForm from "./singleBookOutForm.vue";
export default {
  components: { singleBookOutForm, MultiBookOutForm },
  name: "bookOutsItems",
  props: ["book_out", "groupStudents"],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      editPopupActive: false,
      editMultiActive: false,
      selectedOut: null,
    };
  },
  computed: {
    students: function () {
      return this.groupStudents.map((groupStudent) => {
        let s = this.book_out.students.find(
          (s) => s.student.id == groupStudent.id,
        );
        if (s) return s;
        return { id: null, student: groupStudent };
      });
    },
    delivered: function () {
      return this.students.filter((s) => s.delivered_date).map((s) => s.id);
    },
  },
  methods: {
    clearHandler: function (bookOutId) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to clear give outs of the book?",
        accept: () => {
          let bookOuts = [];
          if (bookOutId) {
            bookOuts = [bookOutId];
          } else {
            bookOuts = this.delivered;
          }
          this.$store
            .dispatch("books/cancelBookOutsAction", { bookOuts })
            .then(() => {
              this.$emit("updateGiveOuts");
            });
        },
      });
    },
    deleteHandler: function (bookOutId) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to delete give outs of the book?",
        accept: () => {
          let bookOuts = [];
          if (bookOutId) {
            bookOuts = [bookOutId];
          } else {
            bookOuts = this.students.filter((s) => s.id).map((s) => s.id);
          }
          this.$store
            .dispatch("books/deleteBookOutsAction", { bookOuts })
            .then(() => {
              this.$emit("updateGiveOuts");
            });
        },
      });
    },
    editAllHandler: function () {
      this.editMultiActive = true;
    },
    editSingleHandler: function (out) {
      this.selectedOut = out;
      this.editPopupActive = true;
    },
    saveSingleOutHandler: async function (out) {
      await this.saveOut(this.selectedOut.id, out);
      this.$emit("updateGiveOuts");
      this.closePopup();
    },
    saveMultipleOutHandler: async function (out) {
      for (var s of this.students) {
        console.log(s);
        if (s.id) await this.saveOut(s.id, out);
      }
      this.$emit("updateGiveOuts");
      this.closePopup();
    },
    saveOut: async function (id, data) {
      this.$store
        .dispatch("books/setBookOutAction", { id, data })
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    },
    closePopup: function () {
      this.selectedOut = null;
      this.editPopupActive = false;
      this.editMultiActive = false;
    },
  },
};
</script>

<style lang="scss">
.book-out {
  padding: 0.65rem 0;
  position: relative;
  &:before {
    content: "";
    height: calc(100% - 3.5rem);
    width: 1px;
    background-color: #ddd;
    display: block;
    position: absolute;
    left: 0.5rem;
    z-index: 0;
    top: 1.2rem;
  }
  &-header {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
    &__title {
      width: 200px;
      z-index: 1;
      .vs-icon {
        display: inline-block;
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }
  }
  &-students {
    margin: 1.65rem 0 1.65rem 2.65rem;
    &__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      gap: 0.65rem;
      margin: 0.4rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      & > div {
        // flex: 1;
      }
      &__name {
        flex: 0.5;
        position: relative;
        .not-delivered {
          color: var(--font-negative-color);
        }
        .not-delivered-flag {
          width: 0.6rem;
          height: 0.6rem;
          background-color: var(--font-negative-color);
          position: absolute;
          left: -1rem;
          border-radius: 50%;
          top: 40%;
        }
      }
      &__gived-items {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
      }
      &__gived {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        margin: 0.2rem 0;
        transition: 0.3s all;
        & > div:first-child {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.4rem;
          flex: 1;
          &:hover {
            font-weight: bold;
          }
        }
      }
      &__comment {
        flex: 1;
      }
      &__delivered-date {
        font-size: 0.85rem;
      }
      &__comment {
        width: 10rem;
      }
    }
  }
}
</style>